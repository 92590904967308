<template>
  <div class="container oci-check-in-out-body">    
    <DCNumberView/>
    <p></p>
    <b-card class="oci-check-in-out-card">  
        <b-row class="pb-0 justify-content-center">
            <b>Confirm</b>
        </b-row>
        <b-row class="align-items-center"><b-col cols="12">DELIVERED</b-col></b-row>
        <div v-for="(item, index) in bookingLines.filter(item => !item.is_collection)" :key="`delivered-${index}`">
        <b-row class="align-items-center">
            <b-col cols="12">
              <template v-if="item.quantity > 0">
                {{ item.quantity }}X {{ item.type.name }}
              </template>
              <template v-else>
                {{ item.notes }} - {{ item.type.name }}
              </template>  
            </b-col>
        </b-row>
        </div>
        <b-row class="align-items-center"><b-col cols="12">COLLECTED</b-col></b-row>
        <div v-for="(item, index) in bookingLines.filter(item => item.is_collection)" :key="`collected-${index}`">
        <b-row class="align-items-center">
            <b-col cols="12">
              <template v-if="item.quantity > 0">
                {{ item.quantity }}X {{ item.type.name }}
              </template>
              <template v-else>
                {{ item.notes }} - {{ item.type.name }}
              </template>  
            </b-col>
        </b-row>
        </div>

        <b-row class="pb-0 align-items-center">
            <b-col>
            <b-form-group
            label="Client name"
            label-for="dc-client-name"
            >            
              <b-form-input
              v-model="currentBooking.print_name"
              placeholder=""
              name="name-edit"
              :disabled="isInputDisabled"
              />
            </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
              <b-form-group
              label="Client signature"
              label-for="dc-sign"
              >
              <div class="signature-container" :disabled="isInputDisabled">
                  <canvas ref="signatureCanvas" class="signature-canvas" :class="{ disabled: isInputDisabled }"></canvas>
              </div>
            </b-form-group>
            </b-col>
        </b-row>

        <b-row class="align-items-center oci-check-in-button-container">
            <b-col class="col-12 driver-upload-button">
            <b-button
                type="submit"
                @click="GoToNextPage"
                class="w-100"
                :disabled="isInputDisabled"
            >
              Confirm and complete
              <feather-icon
                icon="ChevronRightIcon"
                size="12"
              />
            </b-button>
            </b-col>
        </b-row>
        <b-row class="pb-1">
          <b-col class="col-12 driver-back-button">
            <b-button
              type="submit"
              @click="GoToPreviousPage"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
              />              
              Back
            </b-button>
          </b-col>
        </b-row> 

    </b-card>

  </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import { BImg, BCard, BRow, BCol, BForm, BFormGroup, BFormSelect, BInputGroupAppend, BFormInput, BButton, BInputGroup, } from 'bootstrap-vue'
import { bookingsMethods } from '@/components/ui/BookingsFunctions'
import DCNumberView from '@/views/DCNumberView.vue'
import SignaturePad from 'signature_pad';
import { generalMethods } from '@/components/General-Functions'

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    DCNumberView,
  },
  data() {
    return {
      originalClientPrintName: '',
      originalClientSignature: null,
      signaturePad: null,
    };
  },
  mounted() {
    this.originalClientPrintName = this.currentBooking.print_name;
    const canvas = this.$refs.signatureCanvas;
    this.signaturePad = new SignaturePad(canvas, {
      backgroundColor: 'white',
      penColor: 'black',
      minWidth: 0.5,
      maxWidth: 1.5,      
    });
    this.resizeCanvas();
    if (this.currentBooking.signature?.base64) {
      this.originalClientSignature = this.currentBooking.signature.base64;
      this.loadSignature(this.originalClientSignature);
      this.$forceUpdate();
    }
  },
  computed: {
    ...mapGetters({ currentBooking: 'app/getCurrentBookingData' }),
    ...mapGetters({ bookingLines: 'app/getBookingLines' }),
    isInputDisabled() {
      return (
        this.currentBooking?.status === 'completed' ||
        this.currentBooking?.status === 'rejected' ||
        this.currentBooking?.status === null
      );
    }    
  },
  methods: {
    GoToPreviousPage() {
      this.$router.go(-1)
    },
    GoToNextPage() {
      if (this.currentBooking.status === 'to_do' || this.currentBooking.status === 'started') {
        const currentValue = this.currentBooking.print_name;
        const originalValue = this.originalClientPrintName;
        //if (currentValue !== originalValue) {
          let client_signature = this.saveSignature();
          const currentSignatureBase64 = client_signature.replace('data:image/png;base64,', '');
          const hasSignatureChanged = currentSignatureBase64 !== this.originalClientSignature;
          //console.log('this.bookingDockets.client_signatureA', this.bookingDockets.client_signature)
          if (hasSignatureChanged || currentValue !== originalValue) {
            this.currentBooking.signature = {
              __class__: "bytes",
              base64: client_signature.replace('data:image/png;base64,', ''),
            };
            //console.log('this.bookingDockets.client_signatureB', this.currentBooking.signature)
            bookingsMethods.saveCollectionSignatureDetails(this.currentBooking.id, this.currentBooking.print_name, client_signature)
          }
        //}

        bookingsMethods.saveWasteDocketStatus(this.currentBooking.id, 'completed')

        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'pm' : 'am';
        const formattedTime = `${hours % 12 || 12}:${minutes}${ampm}`; // Format as 12-hour clock

        generalMethods.messageBox(`Job completed at ${formattedTime}`, 'You can edit this job until midnight today.', 'success');
      }
      this.$router.push('/driverprojects')
    },
    loadSignature(base64Signature) {
      const canvas = this.$refs.signatureCanvas;
      const ctx = canvas.getContext('2d');
      const image = new Image();
      image.onload = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height); // Draw the signature
      };
      image.src = `data:image/png;base64,${base64Signature}`;
    },
    resizeCanvas() {
      const canvas = this.$refs.signatureCanvas;
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext("2d").scale(ratio, ratio);

      // Clear the canvas to avoid distorted content
      this.signaturePad.clear();
    },
    clearSignature() {
      this.signaturePad.clear();
    },
    saveSignature() {
      /*if (this.signaturePad.isEmpty()) {
        alert("Please provide a signature first.");
        return;
      }
      */
      const signatureImage = this.signaturePad.toDataURL();
      //console.log("Signature Image URL:", signatureImage);
      return signatureImage;
    },
  },  
}
</script>
